<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span span v-if="formModel.id"
                >{{ mode === "edit" ? "修改" : "" }}{{ formModel.name }}</span
                >
                <span span v-if="!formModel.id">创建流程模板</span>
            </div>
        </div>

        <a-form-model :rules="validModel" ref="ruleForm" :layout="form.layout" :model="formModel"
                      v-bind="formItemLayout">
            <a-tabs>
                <a-tab-pane key="1">
                <span slot="tab">
                    基本信息
                </span>

                    <a-alert style="margin-bottom:10px" message="由于各种原因，流程模板的表单暂时没有验证，请自重！" type="info"
                             close-text="我已阅读并了解乱填会带来的后果"/>

                    <a-form-model-item label="流程模板名称" prop="name">
                        <a-input placeholder="请输入流程模板名称" v-model="formModel.name"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="流程分类" prop="category">
                        <a-select v-model="formModel.category" :options="options('workflowTypes')"></a-select>
                    </a-form-model-item>

                    <a-form-model-item label="流程编码" prop="name">
                        <a-input placeholder="请输入流程编码" v-model="formModel.code"></a-input>
                    </a-form-model-item>

                    <a-form-model-item label="是否启用" prop="enabledMark">
                        <a-select v-model="formModel.enabledMark" :options="options('Bool.YN')"></a-select>
                    </a-form-model-item>
                    <!--                    <a-form-model-item label="运行在我的流程发起" prop="method">-->
                    <!--                        <a-select v-model="formModel." :options="options('YN')"></a-select>-->
                    <!--                    </a-form-model-item>-->
                    <a-form-model-item label="移动端是否可用" prop="isInApp">
                        <a-select v-model="formModel.isInApp" :options="options('Int.YN')"></a-select>
                    </a-form-model-item>

                    <a-form-model-item label="允许从我的任务发起" prop="mark">
                        <a-select v-model="formModel.mark" :options="options('Int.YN')"></a-select>
                    </a-form-model-item>

                    <a-alert style="margin-bottom:10px" message="//todo 撤销作废执行操作" type="info" close-text="好的"/>

                    <!-- //todo 撤销作废执行-->
                    <a-form-model-item label="备注" prop="description">
                        <a-textarea placeholder="请输入流程备注" v-model="formModel.description"></a-textarea>
                    </a-form-model-item>

                </a-tab-pane>

                <a-tab-pane key="2" forceRender>
                  <span slot="tab">
                    流程设计
                </span>
                    <div id="workflow-design-area" style="height: 600px"></div>
                </a-tab-pane>
            </a-tabs>
        </a-form-model>
        <div class="modal-footer">
            <a-button type="danger" @click="cancle">取消</a-button>
            <a-button type="primary" @click="onSubmit">保存</a-button>

        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";

    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import sysInterfaceApi from "@/shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import workflowSchemeApi from "@/shared/service-proxies/workflow/services/workflow-scheme-proxies";

    import '@/shared/workflow/zr-workflow-ui.js'
    import Bus from "@/shared/bus/bus";
    import modalHelper from "@/shared/helpers/modal/modal-helper";
    import sysInterfaceSelectForm from "../../systemManagement/sysInterface/selectForm/index";
    import objectHelper from "@/shared/helpers/objectHelper";
    import nodeForm from "./node/nodeForm";
    import lineForm from "./line/lineForm";
    import conditionNodeForm from "./node/conditionNodeForm";
    import confluenceNodeForm from "./node/confluenceNodeForm";


    export default {
        name: "workflow-form",
        mixins: [AppComponentBase, ModalComponentBase],
        components: {},
        data() {
            return {

                fieldsJsonStr: "",
                fieldsJson: {},
                labelCol: {
                    span: 6, offset: 0
                },
                interfaceTypes: [],
                requestMethods: [],
                curInternalInterfaceTree: [],
                internalServices: [],
                fieldMaps: {
                    requestMethods: {
                        value: 'id',
                        label: 'itemName',
                    }
                },
                form: {
                    layout: 'horizontal',
                },
                mode: 'create',
                curDataItemTree: [],
                spinning: false,
                // 表单
                formLayout: "horizontal",
                // 选中的权限过滤
                selectedPermission: [],
                formModel: {
                    id: '',
                    name: '',
                    code: '',
                    category: '',
                    enabledMark: true,
                    isInApp: 1,
                    description: '',
                    scheme: '',
                    mark: 1
                },
                validModel: {
                    //todo 表单验证
                },
            };
        },
        mounted() {
            this.initWorkflowDesigner()


        },

        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            console.log(this.mode)
            console.log(this.formModel)
            if (this.mode == 'edit') {
                workflowSchemeApi.getScheme(this.formModel.schemeId).then(res => {
                    let scheme = res.content
                    this.formModel.scheme = scheme
                })
            }
        },
        computed: {

            formItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        labelCol: {span: 4},
                        wrapperCol: {span: 14},
                    }
                    : {};
            },
            buttonItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        wrapperCol: {span: 14, offset: 4},
                    }
                    : {};
            },
        },
        methods: {
            initWorkflowDesigner() {
                $('#workflow-design-area').lrworkflow({
                    openNode: function (node, nodelist) {
                        let editForm = null
                        console.log(node, nodelist)
                        switch (node.type) {
                            case "endround":
                                return
                                break
                            //条件判断节点专用编辑窗体
                            case "conditionnode":
                                editForm = conditionNodeForm
                                break;
                            case 'confluencenode':
                                editForm = confluenceNodeForm
                                break;
                            default:
                                editForm = nodeForm
                                break
                        }
                        modalHelper.create(
                            editForm,
                            {
                                formModel: {
                                    ...objectHelper.deepClone(node)
                                },
                                nodelist: nodelist
                            },
                            {
                                confirm(data) {
                                    objectHelper.autoMap(data, node, 'soft')
                                    $('#workflow-design-area').lrworkflowSet('updateNodeName', {nodeId: node.id});
                                },
                                width: "800px",
                                isChange: true
                            }
                        )
                    },
                    openLine: function (line, formNode) {
                        console.log('------------------openLine------------------')
                        console.log(formNode)
                        console.log(line)
                        modalHelper.create(
                            lineForm,
                            {
                                formModel: {
                                    ...objectHelper.deepClone(line)
                                },
                                formNode: formNode,
                            },
                            {
                                confirm(data) {
                                    objectHelper.autoMap(data, line, 'hard')
                                    console.log(line)
                                    $('#workflow-design-area').lrworkflowSet('updateLineName', {lineId: line.id});

                                },
                                width: "900px",
                                isChange: true
                            }
                        )

                        // currentLine = line;
                        // fromNode = node;
                        // learun.layerForm({
                        //     id: 'LineForm',
                        //     title: '线条信息设置',
                        //     url: top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/LineForm?layerId=layer_Form',
                        //     width: 700,
                        //     height: 550,
                        //     callBack: function (id) {
                        //         return top[id].acceptClick(function () {
                        //             $('#step-3').lrworkflowSet('updateLineName', { lineId: currentLine.id });
                        //         });
                        //     }
                        // });

                    }
                });

                if (this.mode == 'edit') {
                    setTimeout(() => {
                        $('#workflow-design-area').lrworkflowSet('set', {data: JSON.parse(this.formModel.scheme)});
                    }, 1000)
                }
            },
            removeItem(e) {
                let target = e.target.dataset.target
                let index = e.target.dataset.index
                let temp = this.formModel[target]
                temp = temp.filter((value, i) => {
                    return i != index;
                });
                this.formModel[target] = temp
            },
            cancle() {
                this.close()
            },
            onSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    console.log(valid)
                    // if (!valid) {
                    //     message.error('表单验证失败，请检查后重新提交！')
                    //     return false;
                    // }
                    let postData = {
                        ...this.formModel
                    }
                    postData.scheme = JSON.stringify($('#workflow-design-area').lrworkflowGet())
                    workflowSchemeApi.save(postData).then((res) => {
                        this.$notification["success"]({
                            message: "保存成功",
                        });
                        this.close()
                    }).catch((error) => {
                        console.log('-----oh 我的玉帝 报错了---------')
                        console.log(error)
                    })
                });

            },
            updateSpinning(newV) {
                if (newV === "0") {
                    this.spinning = false;
                } else {
                    this.spinning = true;
                }
            },
        },
    };
</script>

<style scoped lang="less">
    .modal-header {
        margin-bottom: 0;

        .anticon {
            margin-right: 10px;
        }
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>

<style src="@/styles/workflow/zr-workflow-ui.css"></style>


<style>
    .step-pane-base {
        padding: 10px;
        padding-top: 69px;
    }

    .lr-alert {
        position: absolute;
        top: 10px;
        left: 0;
        height: 59px;
        width: 100%;
        padding: 0 10px;
    }

    .lr-alert .alert {
        margin-bottom: 0;
    }

    .lr-alert .alert > i {
        position: relative;
        top: 1px;
        font-size: 15px;
        padding-right: 5px;
    }

    .lr-panel {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        padding-top: 38px;
    }

    .lr-panel .panel-heading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 38px;
    }

    .lr-panel .panel-body {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 383px 0 15px 0;
    }

    .lr-form-item {
        position: absolute;
        top: 0;
        left: 0;
        height: 38px;
        width: 100%;
        padding-right: 20px;
    }

    .wizard {
        z-index: 3;
    }

    .btn-tool-bar {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: right;
        padding: 5px;
        border-bottom: 1px solid #ddd;
    }

    .step-gird-pane {
        padding-top: 41px;
    }

    .btn-tool-bar-left {
        position: absolute;
        left: 5px;
        top: 6px;
        width: 300px;
        height: 28px;
        z-index: 4;
        text-align: left;
    }
</style>
