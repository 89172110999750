<template>
  <a-spin :spinning="spinning">
    <div class="modal-header" style="margin-bottom: 10px">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span>选择岗位</span>
      </div>
    </div>
    <a-form-model :rules="validModel" ref="ruleForm" :model="formModel">
      <a-form-model-item label="岗位" prop="name">
        <a-select
          :showSearch="true"
          placeholder="请选择岗位"
          :fieldMap="fieldMap"
          v-model="formModel.selectedUserId"
          :options="userOptions"
        >
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <div class="modal-footer">
      <a-button type="danger" @click="cancel">取消</a-button>
      <a-button type="primary" @click="confirmEvent">确定</a-button>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { OrganizationPositionServiceProxy } from "../../admin/users/services/positions";
export default {
  name: "user-select-from",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      _userServiceProxy: null,
      spinning: false,
      fieldMap: {
        key: "name",
        label: "name",
        value: "name",
      },
      userList: [],
      formModel: {
        selectedUserId: "",
      },
      validModel: {},
      positionService: null,
      userPosition: [],
      allUserPosition: [],
      formItemLayout: "",
    };
  },
  created() {
    this.positionService = new OrganizationPositionServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.loadData();
  },
  computed: {
    userOptions() {
      return this.allUserPosition.map((item) => {
        return {
          label: item.label,
          value: item.value,
          title: item.label,
        };
      });
    },
  },
  methods: {
    confirmEvent() {
      console.log("confirmEvent！！");
      let userInfo = this.allUserPosition.find((item) => {
        return item.value == this.formModel.selectedUserId;
      });
console.log(userInfo);
      this.confirm(userInfo);
      this.close();
    },
    cancel() {
      this.close();
    },
    async loadData() {
      let allPosition = await this.positionService.getPositionOption();
      this.allUserPosition = allPosition;
    },
  },
};
</script>

<style scoped>
</style>