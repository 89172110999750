<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span>条件字段</span>
            </div>
        </div>
        <a-form-model :rules="validModel" ref="ruleForm" :model="formModel">
            <a-form-model-item label="数据库表" prop="name">
                <a-select :showSearch="true" placeholder="请选择数据库表" @change="tableSelected" :fieldMap="fieldMap"
                          v-model="formModel.table" :options="tableOptions">
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="关联字段" prop="name">
                <a-select :showSearch="true" placeholder="请选择" :fieldMap="fieldMap" v-model="formModel.field1"
                          :options="fieldOptions">
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="比较字段" prop="name">
                <a-select :showSearch="true" placeholder="请选择" :fieldMap="fieldMap" v-model="formModel.field2"
                          :options="fieldOptions">
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="比较类型" prop="name">
                <a-select :showSearch="true" placeholder="请选择" v-model="formModel.compareType">
                    <a-select-option value='1'>等于</a-select-option>
                    <a-select-option value='2'>不等于</a-select-option>
                    <a-select-option value='3'>大于</a-select-option>
                    <a-select-option value='4'>大于等于</a-select-option>
                    <a-select-option value='5'>小于</a-select-option>
                    <a-select-option value='6'>小于等于</a-select-option>
                    <a-select-option value='7'>包含</a-select-option>
                    <a-select-option value='8'>不包含</a-select-option>
                    <a-select-option value='9'>包含于</a-select-option>
                    <a-select-option value='10'>不包含于</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="数据值" prop="name">
                <a-input placeholder="请填写" v-model="formModel.value"></a-input>
            </a-form-model-item>
        </a-form-model>
        <div class="modal-footer">
            <a-button type="danger" @click="cancel">取消</a-button>
            <a-button type="primary" @click="confirmEvent">确定</a-button>
        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";
    import sysInterfaceApi from "@/shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import databaseApi from "@/shared/service-proxies/dataBase/services/database-proxies";

    export default {
        name: "condition-field-set-from",
        mixins: [AppComponentBase, ModalComponentBase],
        data() {
            return {
                spinning: false,
                tables: [],
                fieldMap: {
                    key: 'name',
                    label: 'name',
                    value: 'name'
                },

                fields: [],
                formModel: {
                    table: '',
                    field2: '',
                    field1: '',
                    compareType: '',
                    value:'',
                },
                validModel: {},
                formItemLayout: "",
            }
        }
        , created() {
            this.loadData()

        },
        computed: {
            tableOptions() {
                if (this.tables.length == 0) {
                    return []
                }
                return this.tables
            },

            fieldOptions() {
                if (this.fields.length == 0) {
                    return []
                }
                return this.fields.map(item => {
                    return {
                        label: item.columnName,
                        key: item.columnName,
                        title: item.columnName,
                        value: item.columnName
                    }
                })
            },
        },
        methods: {
            confirmEvent() {
                console.log('confirmEvent！！')
                this.confirm(this.formModel)
                this.close()
            },
            cancel() {
                this.close()
            },
            tableSelected(table) {

                this.fields = []
                if (!!this.formModel.table) {
                    databaseApi.getTableDefined(this.formModel.table).then(
                        (data) => {
                            console.log('databaseApi.getTableDefined')
                            this.fields = data.items
                            console.log(this.fields)
                        }
                    ).catch((error) => {
                        console.log(error)
                    })

                }
            },
            loadData() {
                databaseApi.getTableOptions().then(
                    (data) => {
                        console.log('databaseApi.getTables')
                        console.log(data)
                        this.tables = data
                    }
                ).catch((error) => {
                    console.log(error)
                })

            }
        }
    }
</script>

<style scoped>

</style>