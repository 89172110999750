import workflowContainerDraw from "*.vue";
<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span>节点信息设置</span>
            </div>
        </div>

        <a-form-model :layout="form" :model="formModel" :rules="validModel" ref="ruleForm"
                      v-bind="formItemLayout">
            <a-tabs>
                <a-tab-pane key="1">
                <span slot="tab">
                    基本配置
                </span>

                    <a-form-model-item label="节点标识" prop="name">
                        <a-input placeholder="请输入节点标识" v-model="formModel.id"></a-input>
                    </a-form-model-item>

                    <a-form-model-item label="节点名称" prop="name">
                        <a-input placeholder="请输入节点名称" v-model="formModel.name"></a-input>
                    </a-form-model-item>

                    <!--                     todo 通知策略-->
                    <!--                    <a-form-model-item label="通知策略" prop="type">-->
                    <!--                        <a-select :options="options('workflowTypes')" v-model="formModel.type"></a-select>-->
                    <!--                    </a-form-model-item>-->

                    <!--   /todo 通知策略 -->
                    <a-form-model-item label="所有审核人" prop="isAllAuditor">
                        <a-radio-group  v-model="formModel.isAllAuditor" default-value="1">
                             <a-radio value="1">只要其中一人审核</a-radio>
                            <a-radio value="2">都需要审核</a-radio>
                        </a-radio-group>
                     </a-form-model-item>


                    <a-form-model-item label="审核执行策略" prop="auditExecutType" v-if="formModel.isAllAuditor == '2'">
                        <a-radio-group  v-model="formModel.auditExecutType" default-value="1">
                            <a-radio value="1">有人不同意流转</a-radio>
                            <a-radio value="2">所有人审核完</a-radio>
                        </a-radio-group>
                    </a-form-model-item>


                    <a-form-model-item label="再次审核" prop="auditExecutType"  v-if="formModel.isAllAuditor == '2'">
                        <a-radio-group  v-model="formModel.auditorAgainType" default-value="1">
                            <a-radio value="1">已通过不需要审核</a-radio>
                            <a-radio value="2">已通过需要审核</a-radio>
                        </a-radio-group>
                    </a-form-model-item>




                    <a-form-model-item label="审核方式" prop="auditExecutType"  v-if="formModel.isAllAuditor == '2'">
                        <a-radio-group  v-model="formModel.auditorType" default-value="1" >
                            <a-radio value="1">并行</a-radio>
                            <a-radio value="2">串行</a-radio>
                        </a-radio-group>
                    </a-form-model-item>




                    <a-form-model-item label="允许加签" prop="isSign">
                        <a-radio-group v-model="formModel.isSign" default-value="1">
                            <a-radio value="1">是</a-radio>
                            <a-radio   value="2">是</a-radio>
                        </a-radio-group>
                    </a-form-model-item>

                    <a-form-model-item label="自动同意规则" prop="agreeGz">
                        <!--                        <a-select v-model="formModel.myTask" :options="options('YN')"></a-select>-->
                        <a-select  mode="multiple" name="agreeGz" v-model="formModel.agreeGz">
                            <a-select-option :value="1">处理人就是提交人</a-select-option>
                            <a-select-option :value="2">处理人和上一步的处理人相同</a-select-option>
                            <a-select-option :value="3">处理人审批过</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="无对应处理人" prop="noPeopleGz">
                        <a-radio-group v-model="formModel.noPeopleGz">
                            <a-radio :value="1">超级管理员处理</a-radio>
                            <a-radio :value="2">跳过此步骤</a-radio>
                            <a-radio :value="3">不能提交</a-radio>
                        </a-radio-group>
                    </a-form-model-item>

                </a-tab-pane>


                <a-tab-pane forceRender key="2" v-if="formModel.type!='startround' && formModel.type!='endround' ">
                  <span slot="tab">
                    审核者
                </span>
                    <a-row>
                        <a-button @click="addAuditor" type="primary">添加审核用户</a-button>
                        <a-button @click="addPostAuditor" type="primary">添加岗位</a-button>
                        <a-button @click="addRoleAuditor" type="primary">添加角色</a-button>
                        <!-- <a-button @click="addLevelAuditor" type="primary">添加上下级</a-button> -->
                        <!--                    //todo 选择部门 -->
                    </a-row>
                    <a-card hoverable style="margin: 10px 10px 0 0 ; float: left; width: 130px"
                            v-for="obj in formModel.auditors" :key="obj.id">
                        <a-icon slot="cover" style="width: 60px;
                                     height: 60px;
                                      font-size: 65px;
                                     margin-left: calc(50% - 35px);
                                     margin-right: calc(50% - 35px);
                                     margin-top:15px;
                                     margin-bottom: 15px;
                                     background-size: cover; "
                                type="user"/>


                        <a-card-meta :title="()=>{
                             switch (obj.type) {
                                  case  3:
                                       return  '用户'
                                  case 1:
                                        return '岗位'
                                  case 2:
                                       return '角色'
                                  case 4:
                                       return '上下级'
                                  case 5:
                                       return '表单指定字段'
                                  case 6:
                                       return '某一个节点执行人'
                             }
                        }">
                            <template slot="description">
                                <!-- <p v-if="obj.type == 3">{{ obj.auditorName}}</p> -->
                                <p>{{ obj.auditorName}}</p>
                            </template>
                        </a-card-meta>
                        <template class="ant-card-actions" slot="actions">
                            <a-icon :data-key="obj.id" @click="removeAuditor" key="delete" style="color:red"
                                    type="delete"/>
                        </template>
                    </a-card>
                </a-tab-pane>
                <a-tab-pane forceRender key="3">
                  <span slot="tab">
                     表单设置
                </span>

                    <react-table
                            style="background-color: white; padding: 0px"
                            :table-data="formModel.wfForms"
                            :columns="[ {title:'关联字段', dataIndex:'foreignKey' },{title:'名称', dataIndex:'info.name'},{title:'数据存储域', dataIndex:'info.dataStorageArea'},{title:'描述', dataIndex:'info.description' }]"
                    >
                    </react-table>


                    <a-button @click="addForm" type="primary">添加表单</a-button>
                    <!-- //todo 美化列表 、自定义关联字段-->

                </a-tab-pane>
                <a-tab-pane forceRender key="4" v-if="formModel.type!='startround' && formModel.type!='endround' ">
                  <span slot="tab">
                    超时设置
                </span>
                    <!--    //todo 超时实现-->
                </a-tab-pane>
                <a-tab-pane forceRender key="5" v-if="formModel.type!='startround' && formModel.type!='endround'  ">
                  <span slot="tab">
                      按钮设置
                  </span>
                    <react-table
                            style="background-color: white; padding: 0px"
                            :table-data="formModel.btnList"
                            :columns="[  {title:'名称', dataIndex:'name'},{title:'编码', dataIndex:'code'},{title:'需要签名或盖章', dataIndex:'isSign' },{title:'下一个节点执行人' , dataIndex:'next'}]"
                    >
                    </react-table>
                    <!--     //todo 自定义按钮设置-->
                </a-tab-pane>
            </a-tabs>
        </a-form-model>
        <div class="modal-footer">
            <a-button @click="cancle" type="danger">取消</a-button>
            <a-button @click="onSubmit" type="primary">保存</a-button>
        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";

    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import sysInterfaceApi from "@/shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import workflowSchemeApi from "@/shared/service-proxies/workflow/services/workflow-scheme-proxies";
    import '@/shared/workflow/zr-workflow-ui.js'

    import formSelectForm from '@/app/systemManagement/customize-form/selectForm/index.vue'
    import userSelectForm from '@/app/admin/users/selectForm/index.vue'
    import Bus from "@/shared/bus/bus";
    import modalHelper from "@/shared/helpers/modal/modal-helper";
    import entityHelper from "@/shared/helpers/EntityHelper.js";
    import objectHelper from "../../../../shared/helpers/objectHelper";
    import positionSelectForm from '../../user/positionSelectForm.vue'
    import roleSelectForm from '../../user/roleSelectForm.vue'
    import ReactTable from "@/components/react-table";


    export default {
        name: "node-form",
        mixins: [AppComponentBase, ModalComponentBase],
        components: {ReactTable},
        data() {
            return {
                fieldsJsonStr: "",
                fieldsJson: {},
                labelCol: {
                    span: 6, offset: 0
                },
                form: {
                    layout: 'vertical',
                },
                mode: 'create',
                spinning: false,
                // 表单
                formLayout: "vertical",
                // 选中的权限过滤
                selectedPermission: [],
                formModel: {
                    id: '',
                    auditors: [],
                    wfForms: []
                },
                validModel: {
                    //todo 表单验证
                },
            };
        },
        mounted() {
        },
        created() {
            //获取系统中的所有内部接口
            this.fullData(); // 模态框必须,填充数据到data字段
        },
        computed: {
            fieldsJsonForPerView() {
                try {
                    return JSON.parse(this.fieldsJsonStr)
                } catch (ex) {
                }
            },
            formItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        labelCol: {span: 4},
                        wrapperCol: {span: 14},
                    }
                    : {};
            },
            buttonItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        wrapperCol: {span: 14, offset: 4},
                    }
                    : {};
            },
        },
        methods: {
            removeAuditor(event) {
                let target = event.target
                let key = target.dataset.key
                this.formModel.auditors = this.formModel.auditors.filter((item) => {
                    return item.id != key
                })

            },
            //加审核者（指定用户）
            addAuditor() {
                modalHelper.create(userSelectForm, {}, {
                    confirm: (data) => {
                        console.log(data)
                        let auditorObj = {
                            id: entityHelper.newGuid(),
                            type: 3,
                            auditorId: data.id,
                            auditorName: data.userName
                        }
                        this.formModel.auditors.push(auditorObj)
                        // this.formModel.interfacesConfig.interfaces.push(data)
                        // this.activeInterfaceName = data.name
                    },
                    width: "400px",
                    isChange: true
                })
            },
            addPostAuditor() {
                modalHelper.create(positionSelectForm, {}, {
                    confirm: (data) => {
                        console.log(data)
                        let auditorObj = {
                            id: entityHelper.newGuid(),
                            type: 1,
                            auditorId: data.value,
                            auditorName: data.label
                        }
                        this.formModel.auditors.push(auditorObj)
                        // this.formModel.interfacesConfig.interfaces.push(data)
                        // this.activeInterfaceName = data.name
                    },
                    width: "400px",
                    isChange: true
                })
            },
            addRoleAuditor() {
                modalHelper.create(roleSelectForm, {}, {
                    confirm: (data) => {
                        console.log(data)
                        let auditorObj = {
                            id: entityHelper.newGuid(),
                            type: 2,
                            auditorId: data.id,
                            auditorName: data.displayName
                        }
                        this.formModel.auditors.push(auditorObj)
                        // this.formModel.interfacesConfig.interfaces.push(data)
                        // this.activeInterfaceName = data.name
                    },
                    width: "400px",
                    isChange: true
                })
            },
            addForm() {
                modalHelper.create(formSelectForm, {}, {
                    confirm: (data) => {
                        console.log(data)
                        let formRelation = {
                            id: entityHelper.newGuid(),
                            //type:'user',
                            //关联流程的字段
                            foreignKey: "id",
                            info: data
                        }
                        console.log(this.formModel)
                        this.formModel.wfForms.push(formRelation)
                        // this.formModel.interfacesConfig.interfaces.push(data)
                        // this.activeInterfaceName = data.name
                    },
                    width: "400px",
                    isChange: true
                })
            },
            jsonFieldsStrChanged() {
                console.log('jsonFieldsStrChanged')
                console.log(this.fieldsJsonStr)
                let temp = {
                    name: '',
                    path: '',
                    value: '',
                    type: ''
                }
                let fieldJsonList = []
                try {
                    let fieldJson = JSON.parse(this.fieldsJsonStr)
                    for (let item in fieldJson) {

                        let fieldPath = '$.' + item
                        fieldJsonList.push({
                            name: item,
                            path: fieldPath,
                            value: "",
                            type: typeof fieldJson[item]
                        })
                        if (typeof fieldJson[item] == "object") {
                            let getFieldList = function (path, object, list) {
                                for (let i in object) {
                                    let curFieldPath = path + '.' + i
                                    list.push({
                                        name: i,
                                        path: curFieldPath,
                                        value: "",
                                        type: typeof object[i]
                                    })

                                    if (typeof typeof object[i] == "object") {
                                        return getFieldList(curFieldPath, object[i], list)
                                    }
                                }
                            }
                            getFieldList(fieldPath, fieldJson[item], fieldJsonList)
                        }
                    }
                    console.log(fieldJsonList)
                    this.formModel.fields = fieldJsonList
                } catch (ex) {
                    console.log(ex)
                    message.error('解析响应报文失败！')
                }
            },
            //模拟请求
            simulateRequest(e) {
                console.log(this.formModel.path)
                sysInterfaceApi.simulateRequest(
                    this.formModel.path, this.formModel.type, this.formModel.method, this.formModel.headers, this.formModel.params
                ).then((resp) => {
                    this.formModel.exampleResponse = this.fieldsJsonStr = JSON.stringify(resp)
                    this.jsonFieldsStrChanged()
                    //         console.log(resp)
                })
            },
            removeItem(e) {
                let target = e.target.dataset.target
                let index = e.target.dataset.index
                let temp = this.formModel[target]
                temp = temp.filter((value, i) => {
                    return i != index;
                });
                this.formModel[target] = temp
            },
            addItem(e) {
                console.log(e)
                var target = e.target.dataset.target
                this.formModel[target].push({
                    name: '', value: '', type: ''
                })
            },
            cancle() {
                this.close()
            },
            onSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    console.log(valid)
                    this.confirm(objectHelper.deepClone(this.formModel));
                    this.close();
                });
            },
            updateSpinning(newV) {
                if (newV === "0") {
                    this.spinning = false;
                } else {
                    this.spinning = true;
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .modal-header {
        margin-bottom: 0;

        .anticon {
            margin-right: 10px;
        }
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>

<style src="@/styles/workflow/zr-workflow-ui.css"></style>


<style scoped>
    .user-container {
        border: none;
    }

    .ant-card-body {
        padding: 10px !important;
    }
</style>
