<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span>选择业务表单</span>
            </div>
        </div>
    <a-form-model :rules="validModel" ref="ruleForm" :model="formModel">
        <a-form-model-item label="表单" prop="name">
            <a-select :showSearch="true" placeholder="表单"    v-model="formModel.selectedFormId" :options="formOptions">
            </a-select>
        </a-form-model-item>
<!--        //todo 表单预览-->
    </a-form-model>
    <div class="modal-footer">
        <a-button type="danger" @click="cancel">取消</a-button>
        <a-button type="primary" @click="confirmEvent">确定</a-button>
    </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";
    import formSchemeApi from "@/shared/service-proxies/formScheme/services/form-scheme-proxies";
    export default {
        //一个选择表单的表单
        name: "form-select-form",
        mixins: [AppComponentBase, ModalComponentBase],
        data(){
             return {
                 _userServiceProxy: null,
                 spinning:false,
                 formList:[],
                 formModel:{
                     selectedFormId:"",
                 },
                 validModel:{},
                 formItemLayout:"",
             }
        }
        ,created() {
             this.loadData()
        },
         computed:{
             formOptions(){
                  return  this.formList.map((item)=>{
                      return {
                          id:item.name,
                          value:item.id,
                          title:item.name
                      }
                  })
             }
         },
         methods:{
             confirmEvent(){
                 console.log('confirmEvent！！')
                 let formInfo =  this.formList.find((item)=>{
                      return  item.id   ==  this.formModel.selectedFormId
                 })

                 this.confirm(formInfo)
                 this.close()
             },
             cancel(){
                 this.clodwse()
             },
             loadData(){
                 formSchemeApi.getList({
                     filterText: '',
                 }).then(
                     (data)=>{
                         console.log(data)
                         this.formList = data.items
                     }
                 ).catch((error)=>{console.log(error)})
             }
         }
    }
</script>

<style scoped>

</style>