<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span span>线条设置</span>
            </div>
        </div>

        <a-form-model :layout="form.layout" :model="formModel" :rules="validModel" ref="ruleForm"
                      v-bind="formItemLayout">
            <a-tabs>
                <a-tab-pane key="1">
                <span slot="tab">
                    基本配置
                </span>
                    <a-form-model-item label="线条ID" prop="id">
                        <a-input placeholder="请输入" v-model="formModel.id"></a-input>
                    </a-form-model-item>

                    <a-form-model-item label="名称" prop="name">
                        <a-input placeholder="请输入名称" v-model="formModel.name"></a-input>
                    </a-form-model-item>


                    <a-form-model-item label="颜色" prop="color">
                        <a-select v-model="formModel.color" defaultValue="1">
                            <a-select-option value="1">黑</a-select-option>
                            <a-select-option value="2">红</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <!--   todo-->
                    <!--                    <a-form-model-item label="通知策略" prop="strategy">-->
                    <!--                        <a-select :options="options('workflowTypes')" v-model="formModel.strategy"></a-select>-->
                    <!--                    </a-form-model-item>-->

                    <a-form-model-item label="通过策略" prop="strategy">
                        <a-select v-model="formModel.strategy" default-value="0">
                            <a-select-option value="1">所有情况都通过</a-select-option>
                            <a-select-option value="2">自定义设置</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="通过项" prop="agreeListArr" v-if="formModel.strategy == '2'" >
                        <a-select v-model="formModel.agreeListArr" :options="fromNodeBtnList" mode="multiple">
                        </a-select>
                    </a-form-model-item>
                </a-tab-pane>

                <a-tab-pane forceRender key="2">
                  <span slot="tab">
                    绑定操作
                  </span>
                    <a-form-model-item label="操作类型" prop="operationType">
                        <!--                        <a-select :options="options('workflowTypes')" v-model="formModel.strategy"></a-select>-->
                        <a-radio-group :options="options('TriggerTargetTypes')" v-model="formModel.operationType">
                        </a-radio-group>
                    </a-form-model-item>


                    <a-form-model-item label="sql" prop="strSql" v-if="formModel.operationType=='sql'">
                        <a-textarea v-model="formModel.strSql" class="form-control" style="height: 50%"
                                    placeholder="请填写Sql语句"></a-textarea>
                    </a-form-model-item>


                    <a-form-model-item label="sql" prop="strSql" v-if="formModel.operationType=='sql'">
                        <a-textarea v-model="formModel.strSqlR" class="form-control" style="height: 50%;margin-top:3px;"
                                    placeholder="请填写Sql语句（撤销执行）"/>
                    </a-form-model-item>


                    <a-form-model-item label="接口地址" prop="strInterface" v-if="formModel.operationType=='interface'">
                        <a-textarea v-model="formModel.strInterface" class="form-control" style="height: 50%"
                                    placeholder="请填写接口地址"></a-textarea>
                    </a-form-model-item>


                    <a-form-model-item label="接口地址（撤销执行）" prop="strInterfaceR"
                                       v-if="formModel.operationType=='interface'">
                        <a-textarea v-model="formModel.strInterfaceR" class="form-control"
                                    style="height: 50%;margin-top:3px;" placeholder="请填写接口地址（撤销执行）"/>
                    </a-form-model-item>


                    <a-form-model-item label="方法名" prop="iocName" v-if="formModel.operationType=='ioc'">
                        <a-textarea v-model="formModel.iocName" class="form-control" style="height: 50%"
                                    placeholder="请填写方法名"></a-textarea>
                    </a-form-model-item>


                    <a-form-model-item label="方法名（撤销执行）" prop="iocNameR" v-if="formModel.operationType=='ioc'">
                        <a-textarea v-model="formModel.iocNameR" class="form-control"
                                    style="height: 50%;margin-top:3px;" placeholder="请填写方法名（撤销执行）"/>
                    </a-form-model-item>


                </a-tab-pane>
            </a-tabs>
        </a-form-model>
        <div class="modal-footer">
            <a-button @click="cancle" type="danger">取消</a-button>
            <a-button @click="onSubmit" type="primary">保存</a-button>
        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";

    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import sysInterfaceApi from "@/shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import workflowSchemeApi from "@/shared/service-proxies/workflow/services/workflow-scheme-proxies";
    import '@/shared/workflow/zr-workflow-ui.js'

    import formSelectForm from '@/app/systemManagement/customize-form/selectForm/index.vue'
    import userSelectForm from '@/app/admin/users/selectForm/index.vue'
    import Bus from "@/shared/bus/bus";
    import modalHelper from "@/shared/helpers/modal/modal-helper";
    import entityHelper from "@/shared/helpers/EntityHelper.js";
    import objectHelper from "../../../../shared/helpers/objectHelper";
    import positionSelectForm from '../../user/positionSelectForm.vue'
    import roleSelectForm from '../../user/roleSelectForm.vue'

    export default {
        name: "node-form",
        mixins: [AppComponentBase, ModalComponentBase],
        data() {
            return {
                fieldsJsonStr: "",
                fieldsJson: {},
                labelCol: {
                    span: 6, offset: 0
                },
                form: {
                    layout: 'horizontal',
                },
                fromNode:{},
                mode: 'create',
                spinning: false,
                // 表单
                formLayout: "horizontal",
                // 选中的权限过滤
                selectedPermission: [],
                formModel: {
                    id: '',
                    auditors: [],
                    wfForms: []
                },
                validModel: {
                    //todo 表单验证
                },
            };
        },
        mounted() {
        },
        components: {},
        created() {
            //获取系统中的所有内部接口
            this.fullData(); // 模态框必须,填充数据到data字段
             if (!!this.formModel.agreeList&&typeof this.formModel.agreeList == "string"){
                 this.formModel.agreeListArr = this.formModel.agreeList.split(',')
             }
        },
        computed: {
            fromNodeBtnList() {
                if (!!this.fromNode.btnList) {
                    return this.fromNode.btnList.map(item => {
                        return {
                            label: item.name,
                            key: item.code,
                            value: item.code,
                            title: item.name
                        }
                    })
                }
                return [{ label: '同意', value: 'agree' }, { label: '不同意', value: 'disagree' }]
            },
            fieldsJsonForPerView() {
                try {
                    return JSON.parse(this.fieldsJsonStr)
                } catch (ex) {
                }
            },
            formItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        labelCol: {span: 4},
                        wrapperCol: {span: 14},
                    }
                    : {};
            },
            buttonItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        wrapperCol: {span: 14, offset: 4},
                    }
                    : {};
            },
        },
        methods: {
            removeAuditor(event) {
                let target = event.target
                let key = target.dataset.key
                this.formModel.auditors = this.formModel.auditors.filter((item) => {
                    return item.id != key
                })
            },
            //模拟请求
            simulateRequest(e) {
                console.log(this.formModel.path)
                sysInterfaceApi.simulateRequest(
                    this.formModel.path, this.formModel.type, this.formModel.method, this.formModel.headers, this.formModel.params
                ).then((resp) => {
                    this.formModel.exampleResponse = this.fieldsJsonStr = JSON.stringify(resp)
                    this.jsonFieldsStrChanged()
                    //         console.log(resp)
                })
            },

            removeItem(e) {
                let target = e.target.dataset.target
                let index = e.target.dataset.index
                let temp = this.formModel[target]
                temp = temp.filter((value, i) => {
                    return i != index;
                });
                this.formModel[target] = temp
            },
            addItem(e) {
                console.log(e)
                var target = e.target.dataset.target
                this.formModel[target].push({
                    name: '', value: '', type: ''
                })
            },
            cancle() {
                this.close()
            },
            onSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    console.log(valid)
                     console.log(this.formModel.agreeListArr)
                       if (!!this.formModel.agreeListArr){
                           this.formModel.agreeList  =this.formModel.agreeListArr.join(',')
                       }
                    this.confirm(objectHelper.deepClone(this.formModel));
                    this.close();
                });
            },
            updateSpinning(newV) {
                if (newV === "0") {
                    this.spinning = false;
                } else {
                    this.spinning = true;
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .modal-header {
        margin-bottom: 0;

        .anticon {
            margin-right: 10px;
        }
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>

<style src="@/styles/workflow/zr-workflow-ui.css"></style>


<style>
    .step-pane-base {
        padding: 10px;
        padding-top: 69px;
    }

    .lr-alert {
        position: absolute;
        top: 10px;
        left: 0;
        height: 59px;
        width: 100%;
        padding: 0 10px;
    }

    .lr-alert .alert {
        margin-bottom: 0;
    }

    .lr-alert .alert > i {
        position: relative;
        top: 1px;
        font-size: 15px;
        padding-right: 5px;
    }

    .lr-panel {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        padding-top: 38px;
    }

    .lr-panel .panel-heading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 38px;
    }

    .lr-panel .panel-body {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 383px 0 15px 0;
    }

    .lr-form-item {
        position: absolute;
        top: 0;
        left: 0;
        height: 38px;
        width: 100%;
        padding-right: 20px;
    }

    .wizard {
        z-index: 3;
    }

    .btn-tool-bar {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: right;
        padding: 5px;
        border-bottom: 1px solid #ddd;
    }

    .step-gird-pane {
        padding-top: 41px;
    }

    .btn-tool-bar-left {
        position: absolute;
        left: 5px;
        top: 6px;
        width: 300px;
        height: 28px;
        z-index: 4;
        text-align: left;
    }

    .ant-card-body {
        padding: 10px !important;
    }
</style>
