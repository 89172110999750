<template>
  <a-spin :spinning="spinning">
    <div class="modal-header" style="margin-bottom: 10px">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span>选择角色</span>
      </div>
    </div>
    <a-form-model :rules="validModel" ref="ruleForm" :model="formModel">
      <a-form-model-item label="角色" prop="name">
        <a-select
          :showSearch="true"
          placeholder="请选择角色"
          :fieldMap="fieldMap"
          v-model="formModel.selectedUserId"
          :options="userOptions"
        >
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <div class="modal-footer">
      <a-button type="danger" @click="cancel">取消</a-button>
      <a-button type="primary" @click="confirmEvent">确定</a-button>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { RoleServiceProxy } from "@/shared/service-proxies";
export default {
  name: "user-select-from",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      _roleServiceProxy: null,
      selectedPermission: [],
      spinning: false,
      fieldMap: {
        key: "name",
        label: "name",
        value: "name",
      },
      userList: [],
      formModel: {
        selectedUserId: "",
      },
      validModel: {},
      roleList: [],
      formItemLayout: "",
    };
  },
  created() {
    this._roleServiceProxy = new RoleServiceProxy(this.$apiUrl, this.$api);
    this.loadData();
  },
  computed: {
    userOptions() {
      return this.roleList.map((item) => {
        return {
          label: item.displayName,
          value: item.id,
          title: item.displayName,
        };
      });
    },
  },
  methods: {
    confirmEvent() {
      console.log("confirmEvent！！");
      let userInfo = this.roleList.find((item) => {
        return item.id == this.formModel.selectedUserId;
      });
      console.log(userInfo);
      this.confirm(userInfo);
      this.close();
    },
    cancel() {
      this.close();
    },
    loadData() {
      this._roleServiceProxy
        .getPaged(this.selectedPermission)
        .then((data) => {
          console.log(data);
          this.roleList = data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
</style>