import workflowContainerDraw from "*.vue";
<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span >节点信息设置</span>
            </div>
        </div>

        <a-form-model :layout="form.layout" :model="formModel" :rules="validModel" ref="ruleForm"
                      v-bind="formItemLayout">
            <a-tabs>
                <a-tab-pane key="1">
                <span slot="tab">
                    基本配置
                </span>
                    <a-form-model-item label="节点标识" prop="name">
                        <a-input placeholder="请输入节点标识" v-model="formModel.id"></a-input>
                    </a-form-model-item>


                    <a-form-model-item label="节点名称" prop="name">
                        <a-input placeholder="请输入节点名称" v-model="formModel.name"></a-input>
                    </a-form-model-item>
                </a-tab-pane>

                <a-tab-pane forceRender key="2">
                  <span slot="tab">
                     字段条件设置
                </span>
                    <react-table
                            style="background-color: white; padding: 0px"
                            :table-data="formModel.conditions"
                            :columns="[
                                {title:'数据表', dataIndex:'table' },
                                {title:'关联字段', dataIndex:'field1'},
                                {title:'比较字段', dataIndex:'field2'},
                                {title:'比较类型', dataIndex:'compareType' },
                                {title:'比较类型', dataIndex:'value' }]"
                    >
                    </react-table>

                    <a-button @click="addField" type="primary">添加字段</a-button>
                </a-tab-pane>

                <a-tab-pane forceRender key="3">
                <span slot="tab">
                    Sql条件设置
                </span>
                    <a-alert   message="注意：请在开发人员指导下进行配置SQL语句（{processId}流程发起实例主键{userId}用户Id{userAccount}用户账号{companyId}用户公司{departmentId}用户部门）！"
                               type="warning"  />
                    <a-textarea v-model="formModel.conditionSql"  style="height: 300px;width:100%;margin-top:10px" placeholder="请填写SQL语句">
                    </a-textarea>
                </a-tab-pane>


            </a-tabs>
        </a-form-model>
        <div class="modal-footer">
            <a-button @click="cancle" type="danger">取消</a-button>
            <a-button @click="onSubmit" type="primary">保存</a-button>
        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";

    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import sysInterfaceApi from "@/shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import workflowSchemeApi from "@/shared/service-proxies/workflow/services/workflow-scheme-proxies";
    import '@/shared/workflow/zr-workflow-ui.js'

    import formSelectForm from '@/app/systemManagement/customize-form/selectForm/index.vue'
    import userSelectForm from '@/app/admin/users/selectForm/index.vue'
    import Bus from "@/shared/bus/bus";
    import modalHelper from "@/shared/helpers/modal/modal-helper";
    import entityHelper from "@/shared/helpers/EntityHelper.js";
    import objectHelper from "../../../../shared/helpers/objectHelper";
    import positionSelectForm from '../../user/positionSelectForm.vue'
    import roleSelectForm from '../../user/roleSelectForm.vue'
    import ReactTable from "@/components/react-table";
    import conditionFieldSetForm from "./conditionFieldSetForm";

    export default {
        name: "condition-node-form",
        mixins: [AppComponentBase, ModalComponentBase],
        components: {ReactTable},
        data() {
            return {
                fieldsJsonStr: "",
                fieldsJson: {},
                labelCol: {
                    span: 6, offset: 0
                },
                form: {
                    layout: 'horizontal',
                },
                mode: 'create',
                spinning: false,
                // 表单
                formLayout: "horizontal",
                // 选中的权限过滤
                selectedPermission: [],
                formModel: {
                    id: '',
                    auditors: [],
                    wfForms: []
                },
                validModel: {
                    //todo 表单验证
                },
            };
        },
        mounted() {
        },
        created() {
            //获取系统中的所有内部接口
            this.fullData(); // 模态框必须,填充数据到data字段
        },
        computed: {
            fieldsJsonForPerView() {
                try {
                    return JSON.parse(this.fieldsJsonStr)
                } catch (ex) {
                }
            },
            formItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        labelCol: {span: 4},
                        wrapperCol: {span: 14},
                    }
                    : {};
            },
            buttonItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        wrapperCol: {span: 14, offset: 4},
                    }
                    : {};
            },
        },
        methods: {
            removeAuditor(event) {
                let target = event.target
                let key = target.dataset.key
                this.formModel.auditors = this.formModel.auditors.filter((item) => {
                    return item.id != key
                })

            },
            //加审核者（指定用户）
            addAuditor() {
                modalHelper.create(userSelectForm, {}, {
                    confirm: (data) => {
                        console.log(data)
                        let auditorObj = {
                            id: entityHelper.newGuid(),
                            type: 3,
                            auditorId: data.id,
                            auditorName: data.userName
                        }
                        this.formModel.auditors.push(auditorObj)
                        // this.formModel.interfacesConfig.interfaces.push(data)
                        // this.activeInterfaceName = data.name
                    },
                    width: "400px",
                    isChange: true
                })
            },
            addPostAuditor() {
                modalHelper.create(positionSelectForm, {}, {
                    confirm: (data) => {
                        console.log(data)
                        let auditorObj = {
                            id: entityHelper.newGuid(),
                            type: 1,
                            auditorId: data.value,
                            auditorName: data.label
                        }
                        this.formModel.auditors.push(auditorObj)
                        // this.formModel.interfacesConfig.interfaces.push(data)
                        // this.activeInterfaceName = data.name
                    },
                    width: "400px",
                    isChange: true
                })
            },
            addRoleAuditor() {
                modalHelper.create(roleSelectForm, {}, {
                    confirm: (data) => {
                        console.log(data)
                        let auditorObj = {
                            id: entityHelper.newGuid(),
                            type: 2,
                            auditorId: data.id,
                            auditorName: data.displayName
                        }
                        this.formModel.auditors.push(auditorObj)
                        // this.formModel.interfacesConfig.interfaces.push(data)
                        // this.activeInterfaceName = data.name
                    },
                    width: "400px",
                    isChange: true
                })
            },
            addField() {
                modalHelper.create(conditionFieldSetForm,{}, {
                    confirm: (data) => {
                        this.formModel.conditions.push(data)
                    },
                    width: "500px",
                    isChange: true
                })
            },
            jsonFieldsStrChanged() {
                console.log('jsonFieldsStrChanged')
                console.log(this.fieldsJsonStr)
                let temp = {
                    name: '',
                    path: '',
                    value: '',
                    type: ''
                }
                let fieldJsonList = []
                try {
                    let fieldJson = JSON.parse(this.fieldsJsonStr)
                    for (let item in fieldJson) {

                        let fieldPath = '$.' + item
                        fieldJsonList.push({
                            name: item,
                            path: fieldPath,
                            value: "",
                            type: typeof fieldJson[item]
                        })
                        if (typeof fieldJson[item] == "object") {
                            let getFieldList = function (path, object, list) {
                                for (let i in object) {
                                    let curFieldPath = path + '.' + i
                                    list.push({
                                        name: i,
                                        path: curFieldPath,
                                        value: "",
                                        type: typeof object[i]
                                    })

                                    if (typeof typeof object[i] == "object") {
                                        return getFieldList(curFieldPath, object[i], list)
                                    }
                                }
                            }
                            getFieldList(fieldPath, fieldJson[item], fieldJsonList)
                        }
                    }
                    console.log(fieldJsonList)
                    this.formModel.fields = fieldJsonList
                } catch (ex) {
                    console.log(ex)
                    message.error('解析响应报文失败！')
                }
            },

            //模拟请求
            simulateRequest(e) {
                console.log(this.formModel.path)
                sysInterfaceApi.simulateRequest(
                    this.formModel.path, this.formModel.type, this.formModel.method, this.formModel.headers, this.formModel.params
                ).then((resp) => {
                    this.formModel.exampleResponse = this.fieldsJsonStr = JSON.stringify(resp)
                    this.jsonFieldsStrChanged()
                    //         console.log(resp)
                })
            },

            removeItem(e) {
                let target = e.target.dataset.target
                let index = e.target.dataset.index
                let temp = this.formModel[target]
                temp = temp.filter((value, i) => {
                    return i != index;
                });
                this.formModel[target] = temp
            },
            addItem(e) {
                console.log(e)
                var target = e.target.dataset.target
                this.formModel[target].push({
                    name: '', value: '', type: ''
                })
            },
            cancle() {
                this.close()
            },
            onSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    console.log(valid)
                    this.confirm(objectHelper.deepClone(this.formModel));
                    this.close();
                });
            },
            updateSpinning(newV) {
                if (newV === "0") {
                    this.spinning = false;
                } else {
                    this.spinning = true;
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .modal-header {
        margin-bottom: 0;

        .anticon {
            margin-right: 10px;
        }
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>

<style src="@/styles/workflow/zr-workflow-ui.css"></style>


<style scoped>

    .user-container{
         border:none;
    }
    .step-pane-base {
        padding: 10px;
        padding-top: 69px;
    }

    .lr-alert {
        position: absolute;
        top: 10px;
        left: 0;
        height: 59px;
        width: 100%;
        padding: 0 10px;
    }

    .lr-alert .alert {
        margin-bottom: 0;
    }

    .lr-alert .alert > i {
        position: relative;
        top: 1px;
        font-size: 15px;
        padding-right: 5px;
    }

    .lr-panel {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        padding-top: 38px;
    }

    .lr-panel .panel-heading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 38px;
    }

    .lr-panel .panel-body {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 383px 0 15px 0;
    }

    .lr-form-item {
        position: absolute;
        top: 0;
        left: 0;
        height: 38px;
        width: 100%;
        padding-right: 20px;
    }

    .wizard {
        z-index: 3;
    }

    .btn-tool-bar {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: right;
        padding: 5px;
        border-bottom: 1px solid #ddd;
    }

    .step-gird-pane {
        padding-top: 41px;
    }

    .btn-tool-bar-left {
        position: absolute;
        left: 5px;
        top: 6px;
        width: 300px;
        height: 28px;
        z-index: 4;
        text-align: left;
    }

    .ant-card-body {
        padding: 10px !important;
    }
</style>
